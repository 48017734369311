import React from "react";
import { Outlet } from "react-router-dom";
import { LanguageProvider } from "./LanguageContext";

import Loader from "./Loader";
import Header from "./Header";
import Footer from "./Footer";

const AppLayout = () => {
  return (
    <div>
      <Loader />
      <div>
        <LanguageProvider>
          <Header />
          <Outlet />
          <Footer />
        </LanguageProvider>
      </div>
    </div>
  );
};

export default AppLayout;
