import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "reactstrap";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";

import YearChange from "./changePercentage/yearChange";
import WeekChange from "./changePercentage/weekChange";
import TopGainers from "./trading_data/TopGainers";
import TopLoosers from "./trading_data/TopLoosers";
import SparklineData from "./sparkline";
import MonthChange from "./changePercentage/monthChange";
import TrendingAssests from "./trading_data/Trending";
import RecentlyAddedToken from "./trading_data/RecentlyAddedToken";

import "./coinList.css";

import "./tableNavbar.css";

import { useCurrency } from "./context/CurrencyContext";

import trimbexBaner from "../assets/images/Mid Banner BG-01.jpg";
import starGoldCoinBanner from "../assets/images/stargoldcoinbanner.jpg";
import spaceSolarPowerStation from "../assets/images/Space Solar Power Station.jpg";
import axios from "axios";

const API_BASE = "https://coincurd.com";

function CarouselColumn() {
  return (
    <div>
      <Carousel
        autoPlay
        infiniteLoop
        showIndicators={false}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        emulateTouch
      >
        <div>
          <TrendingAssests />
        </div>
        <div>
          <RecentlyAddedToken />
        </div>
      </Carousel>
    </div>
  );
}

function BannerCarousel() {
  return (
    <div>
      <Carousel
        autoPlay
        infiniteLoop
        showIndicators={false}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        emulateTouch
      >
        <div>
          <a
            href=" https://bscscan.com/token/0xbDFB01cc1c5EfFbF1534E959F0169523C0357188"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={starGoldCoinBanner}
              alt="Star Gold Coin Banner"
              style={{ height: "100%", width: "100%" }}
            />
          </a>
        </div>
        <div>
          <a href="https://trimbex.com/" target="_blank" rel="noreferrer">
            <img
              src={trimbexBaner}
              alt="Trimbex Banner"
              style={{ height: "100%", width: "100%" }}
            />
          </a>
        </div>
      </Carousel>
    </div>
  );
}

const Dashboard = () => {
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const [showComponents, setShowComponents] = useState(true);
  const [showFullParagraph, setShowFullParagraph] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const [watchlist, setWatchlist] = useState(
    JSON.parse(localStorage.getItem("watchlist")) || [{}]
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const { selectedCurrency, selectedCurrencyRate } = useCurrency();

  async function allCurrencyTable() {
    try {
      const exchagneAPi = await axios.get(
        `${API_BASE}/exchange/viewAllCoins`
      );

      let result = exchagneAPi.data.data;

      if (result) {
        setData(result);
        setLoading(false);
      }
    } catch (error) {
      setError("Waiting for server response!");
      setLoading(false);
    }
  }

  useEffect(() => {
    allCurrencyTable();
  }, []);

  const handleRowClick = (row) => {
    navigate(`/currencies/${row.coinId}`);
    localStorage.setItem("symbol", row.symbol);
  };

  const handleCoinNameClick = (coinId) => {
    navigate(`/currencies/${coinId}`);
  };

  const toggleWatch = (row) => {
    const index = watchlist.findIndex((item) => item.coinId === row.coinId);
    if (index === -1) {
      setWatchlist([...watchlist, row]);
    } else {
      setWatchlist(watchlist.filter((item) => item.coinId !== row.coinId));
    }
  };

  useEffect(() => {
    localStorage.setItem("watchlist", JSON.stringify(watchlist));
  }, [watchlist]);

  useEffect(() => {
    const storedWatchlist = JSON.parse(localStorage.getItem("watchlist"));
    if (storedWatchlist) {
      setWatchlist(storedWatchlist);
    }
  }, []);

  let isWatched = (item) => {
    return watchlist.some((watchlist) => watchlist.coinId === item.coinId);
  };

  const getButtonLabel = (coin) => {
    return isWatched(coin) ? (
      <FontAwesomeIcon icon={solidStar} style={{ width: "9px" }} />
    ) : (
      <FontAwesomeIcon icon={regularStar} style={{ width: "9px" }} />
    );
  };

  const getButtonColor = (coin) => {
    return isWatched(coin) ? "#ff8000" : "#000";
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  let filteredCoins = [];

  if (Array.isArray(data)) {
    filteredCoins = data.filter(
      (coin) =>
        coin.coinName &&
        coin.symbol &&
        (coin.coinName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          coin.symbol.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  }

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortDirection("asc");
      setSortColumn(column);
    }
  };

  const sortedCoins = [...filteredCoins].sort((a, b) => {
    if (sortColumn === null) {
      return 0;
    }

    const aValue = a[sortColumn];
    const bValue = b[sortColumn];

    if (typeof aValue === "number" && typeof bValue === "number") {
      return sortDirection === "asc" ? aValue - bValue : bValue - aValue;
    } else {
      return sortDirection === "asc"
        ? aValue.toString().localeCompare(bValue)
        : bValue.toString().localeCompare(aValue);
    }
  });

  const handleToggleComponents = () => {
    setShowComponents(!showComponents);
  };

  const paragraph = (
    <div>
      <p>
        The total crypto market volume over the last 24 hours is $30.09B, which
        makes an 8.14% decrease. The total volume in DeFi is currently $2.20B,
        7.32% of the total crypto market 24-hour volume.
        <br />
        The volume of all stable coins is now $27.63B, which is 91.82% of the
        total crypto market 24-hour volume. Bitcoin currently has a dominance of
        45.96%, a decrease of 0.13% over the day.
      </p>
    </div>
  );

  const firstSentence =
    " The global crypto market cap is $1.11T, a 1.05% increase over the last day.";

  const handleToggleParagraph = () => {
    setShowFullParagraph(!showFullParagraph);
  };

  return (
    <div className="p-2 ps-3 pt-0">
      <div className="d-flex justify-content-between mt-3">
        <h6>Today's Cryptocurrency Prices by Market Cap</h6>
        <div className="text-end d-flex justify-content-between align-items-center">
          <div className="p-2" style={{ fontSize: "14px", color: "#58667E" }}>
            Highlights
          </div>
          <label className="switch">
            <input
              type="checkbox"
              checked={showComponents}
              onChange={handleToggleComponents}
            />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
      <p>
        {firstSentence}
        {!showFullParagraph && (
          <span
            onClick={handleToggleParagraph}
            className="text-primary ps-1"
            style={{ cursor: "pointer" }}
          >
            Read More
          </span>
        )}
      </p>

      {showFullParagraph && (
        <div>
          {paragraph}
          <span
            onClick={handleToggleParagraph}
            className="text-primary"
            style={{ cursor: "pointer" }}
          >
            Read Less
          </span>
        </div>
      )}

      {showComponents && (
        <Row lg={12}>
          <Col md="4" className="p-1">
            <CarouselColumn />
          </Col>

          <Col md="4" className="p-1">
            <TopGainers />
          </Col>

          <Col md="4" className="p-1">
            <TopLoosers />
          </Col>
        </Row>
      )}

      <Row lg={12}>
        <Col md="6" className="p-0 pt-2 pb-2 ps-1">
          <div>
            <a href="https://sspstoken.com/" target="_blank" rel="noreferrer">
              <img
                src={spaceSolarPowerStation}
                alt="Space Solar Power Station"
                style={{ height: "100%", width: "100%" }}
              />
            </a>
          </div>
        </Col>
        <Col md="6" className="p-0 pt-2 pb-2 ps-1">
          <BannerCarousel />
        </Col>
      </Row>

      <Row lg={12} className="ps-0">
        <Col lg={12} className="p-1 pb-0 ps-0">
          <div className="topnav d-flex justify-content-between">
            <a href="/watchlist" className="text-decoration-none nav-link">
              <span>
                <i className="fa fa-star-o"></i> Favourites
              </span>
            </a>

            <div className="search-container">
              <input
                maxLength="200"
                spellCheck="false"
                className="form-control search-bar"
                type="text"
                placeholder="Search coin, symbol"
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
              <FontAwesomeIcon icon={faSearch} className="search-icon" />
            </div>
          </div>
        </Col>

        <Col lg={12} className="p-1 pt-0 ps-0" id="targetSection">
          {loading ? (
            <p className="text-primary text-center">Loading...</p>
          ) : error ? (
            <p className="text-primary text-center">{error}</p>
          ) : (
            <div className="table-responsive">
              <table className="table">
                <thead className="bg-white border-top">
                  <tr>
                    <th
                      onClick={() => handleSort("rank")}
                      className={`sorting-header ${
                        sortColumn === "rank" ? "sorting-active" : ""
                      }`}
                    >
                      #
                      <span className={`sorting-icon`}>
                        {sortColumn === "rank" && sortDirection === "asc"
                          ? "▲"
                          : "▼"}
                      </span>
                    </th>
                    <th
                      onClick={() => handleSort("coinName")}
                      className={`sorting-header ${
                        sortColumn === "coinName" ? "sorting-active" : ""
                      }`}
                    >
                      Name
                      <span className={`sorting-icon`}>
                        {sortColumn === "coinName" && sortDirection === "asc"
                          ? "▲"
                          : "▼"}
                      </span>
                    </th>
                    <th
                      onClick={() => handleSort("price")}
                      className={`sorting-header ${
                        sortColumn === "price" ? "sorting-active" : ""
                      }`}
                    >
                      Price
                      <span className={`sorting-icon`}>
                        {sortColumn === "price" && sortDirection === "asc"
                          ? "▲"
                          : "▼"}
                      </span>
                    </th>

                    <th>1W%</th>
                    <th>1M%</th>
                    <th>1Y%</th>
                    <th
                      onClick={() => handleSort("volume")}
                      className={`sorting-header ${
                        sortColumn === "volume" ? "sorting-active" : ""
                      }`}
                    >
                      Volume
                      <span className={`sorting-icon`}>
                        {sortColumn === "volume" && sortDirection === "asc"
                          ? "▲"
                          : "▼"}
                      </span>
                    </th>

                    <th
                      onClick={() => handleSort("circulatingSupply")}
                      className={`sorting-header ${
                        sortColumn === "circulatingSupply"
                          ? "sorting-active"
                          : ""
                      }`}
                    >
                      Supply
                      <span className={`sorting-icon`}>
                        {sortColumn === "circulatingSupply" &&
                        sortDirection === "asc"
                          ? "▲"
                          : "▼"}
                      </span>
                    </th>
                    <th
                      onClick={() => handleSort("marketCap")}
                      className={`sorting-header ${
                        sortColumn === "marketCap" ? "sorting-active" : ""
                      }`}
                    >
                      Market Cap
                      <span className={`sorting-icon`}>
                        {sortColumn === "marketCap" && sortDirection === "asc"
                          ? "▲"
                          : "▼"}
                      </span>
                    </th>
                    <th>7days</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedCoins.map((row, index) => (
                    <tr
                      key={row.coinId}
                      onClick={() => handleRowClick(row)}
                      style={{ color: "#000" }}
                    >
                      <td
                        style={{
                          padding: "10px 1px",
                          whiteSpace: "nowrap",
                          overflowX: "auto",
                        }}
                      >
                        <div>
                          <button
                            onClick={() => toggleWatch(row)}
                            style={{
                              color: getButtonColor(row),
                              border: "none",
                              background: "none",
                            }}
                          >
                            {getButtonLabel(row)}
                          </button>
                          &nbsp;&nbsp;
                          {index + 1}
                        </div>
                      </td>
                      <td
                        className="fw-bold"
                        style={{
                          padding: "10px 0px 0px 0px",
                          whiteSpace: "nowrap",
                          overflowX: "auto",
                        }}
                      >
                        <div
                          onClick={() => handleCoinNameClick(row.coinId)}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            style={{ width: "20px" }}
                            src={`/coinImg/${row.symbol.toLowerCase()}.png`}
                            onError={(e) => {
                              e.target.onerror = "";
                              e.target.src = "/coinImg/sample.png";
                            }}
                            alt=""
                          />
                          &nbsp;
                          {row.coinName}
                          <span style={{ color: "#808A9D" }} className="ps-1">
                            {row.symbol}
                          </span>
                        </div>
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          whiteSpace: "nowrap",
                          overflowX: "auto",
                        }}
                      >
                        {selectedCurrency.currencySymbol === null &&
                        selectedCurrency.type === "fiat"
                          ? `${selectedCurrency.symbol} ${Number(
                              row.price / selectedCurrency.rateUsd
                            ).toFixed(4)}`
                          : selectedCurrency.type === "crypto"
                          ? `${Number(
                              row.price / selectedCurrency.rateUsd
                            ).toFixed(6)} ${selectedCurrency.symbol}`
                          : `${selectedCurrency.currencySymbol}${(
                              row.price / selectedCurrency.rateUsd
                            ).toFixed(6)}`}
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          whiteSpace: "nowrap",
                          overflowX: "auto",
                        }}
                      >
                        {<WeekChange coinId={row.coinId} /> ? (
                          <WeekChange coinId={row.coinId} />
                        ) : (
                          ""
                        )}
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          whiteSpace: "nowrap",
                          overflowX: "auto",
                        }}
                      >
                        {<MonthChange coinId={row.coinId} /> ? (
                          <MonthChange coinId={row.coinId} />
                        ) : (
                          ""
                        )}
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          whiteSpace: "nowrap",
                          overflowX: "auto",
                        }}
                      >
                        {<YearChange coinId={row.coinId} /> ? (
                          <YearChange coinId={row.coinId} />
                        ) : (
                          ""
                        )}
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          whiteSpace: "nowrap",
                          overflowX: "auto",
                        }}
                      >
                        {selectedCurrency.currencySymbol === null &&
                        selectedCurrency.type === "fiat"
                          ? `${selectedCurrency.symbol} ${Number(
                              row.volume / selectedCurrency.rateUsd
                            ).toFixed(2)}`
                          : selectedCurrency.type === "crypto"
                          ? `${Number(
                              row.volume / selectedCurrency.rateUsd
                            ).toFixed(2)} ${selectedCurrency.symbol}`
                          : `${selectedCurrency.currencySymbol}${(
                              row.volume / selectedCurrency.rateUsd
                            ).toFixed(2)}`}
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          whiteSpace: "nowrap",
                          overflowX: "auto",
                        }}
                      >
                        {`${Number(row.circulatingSupply).toFixed(0)} ${
                          row.symbol
                        }`}
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          whiteSpace: "nowrap",
                          overflowX: "auto",
                        }}
                      >
                        {selectedCurrency.currencySymbol === null &&
                        selectedCurrency.type === "fiat"
                          ? `${selectedCurrency.symbol} ${Number(
                              row.marketCap / selectedCurrency.rateUsd
                            ).toFixed(2)}`
                          : selectedCurrency.type === "crypto"
                          ? `${Number(
                              row.marketCap / selectedCurrency.rateUsd
                            ).toFixed(2)} ${selectedCurrency.symbol}`
                          : `${selectedCurrency.currencySymbol}${(
                              row.marketCap / selectedCurrency.rateUsd
                            ).toFixed(2)}`}
                      </td>
                      <td style={{ padding: "0px 0" }}>
                        {<SparklineData coinId={row.coinId} /> ? (
                          <SparklineData coinId={row.coinId} />
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
