import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

import DataTable from "react-data-table-component";

import "./../style.css";

const API_BASE = "https://coincurd.com";

const MarketTables = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { coinId } = useParams();

  const symbol = localStorage.getItem("symbol");

  const navigate = useNavigate();

  const fetchMarketData = async () => {
    try {
      const response = await axios.get(
        // `${API_BASE}/api/recentMarket/${coinId.toLowerCase()}`
        `${API_BASE}/exchange/viewCrypto/market/${symbol.toLowerCase()}`
      );
      const result = response.data.data;
      const dataWithSerial = result.map((item, index) => ({
        ...item,
        serial: index + 1,
      }));
      if (response && result) {
        setData(dataWithSerial);
        setLoading(false);
      }
    } catch (error) {
      setError("Waiting for server response!");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMarketData();
  }, [coinId]);

  const tableColumns = [
    {
      name: "#",
      selector: (row) => row.serial,
      sortable: true,
      center: false,
      style: {
        fontSize: "10px",
        fontWeight: "bold",
      },
      width: "9%",
    },
    {
      name: "Exchange",
      selector: (row) => row.exchangeId,
      sortable: true,
      center: false,
      style: {
        fontSize: "10px",
        fontWeight: "bold",
        cursor: "pointer",
      },
      width: "16%",
    },
    {
      name: "Pairs",
      selector: (row) => row.pairName,

      sortable: true,
      center: false,
      style: {
        fontSize: "10px",
        fontWeight: "bold",
        cursor: "pointer",
      },
      width: "14%",
    },

    {
      name: "Price",
      selector: (row) => Number(row.price).toFixed(4),
      sortable: true,
      center: false,
      style: {
        fontSize: "10px",
        fontWeight: "bold",
      },
      width: "14%",
    },

    {
      name: "Volume",
      selector: (row) => (row.volume ? Number(row.volume).toFixed(4) : ""),
      sortable: true,
      center: false,
      style: {
        fontSize: "10px",
        fontWeight: "bold",
      },
      width: "14%",
    },

    {
      name: "Updated",
      selector: (row) => (
        <div>
          {new Date(row.updatedAt).toLocaleString("en-IN", {
            hour12: "true",
          })}
        </div>
      ),
      sortable: true,
      center: true,
      style: {
        fontSize: "10px",
        fontWeight: "bold",
      },
    },
  ];

  const handleRowClick = (row) => {
    navigate(`/exchanges/${row.exchangeId}`);
  };

  return (
    <div>
      {loading ? (
        <p className="text-primary">Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <div className="datatable-container fw-bold ps-2">
          <p className="fw-bold text-start bg-white p-2 fs-5 ps-3">
            {coinId} Market
          </p>

          <DataTable
            data={data}
            columns={tableColumns}
            pagination={true}
            paginationPerPage={25}
            onRowClicked={handleRowClick}
          />
        </div>
      )}
    </div>
  );
};

export default MarketTables;
