import React, { useEffect, useState } from "react";

const SupplyProgressBar = ({ maxSupply, supply }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const progressPercentage =
      maxSupply && supply ? (supply / maxSupply) * 100 : 0;
    setProgress(progressPercentage);
  }, [maxSupply, supply]);

  return (
    <div>
      <div className="text-end text-secondary fw-bold">
        {Number(progress).toFixed(2)} %
      </div>
      <div className="progress-bar-container">
        <div
          className="progress-bar"
          style={{ width: `${progress}%`, backgroundColor: "#A6B0C3" }}
        ></div>
      </div>
    </div>
  );
};

export default SupplyProgressBar;
