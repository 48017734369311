import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col } from "reactstrap";
import axios from "axios";
import banner4 from "../../assets/images/Up Banner ban-01.jpg";
import banner5 from "../../assets/images/Up Banner ban-02.jpg";
import "./exchange.css";

const API_BASE = "https://coincurd.com";

const ExchangePair = () => {
  const [exPair, setExPair] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const { exchangeId } = useParams();
  const navigate = useNavigate();

  const fetchExchangePair = async (page) => {
    try {
      const exchagneAPi = await axios.get(
        `${API_BASE}/exchange/viewExchange/market/${exchangeId}?page=${page}`
      );

      const result = exchagneAPi.data.data;
      setLoading(false);
      return result;
    } catch (error) {
      setError("Waiting for server response!");
      setLoading(false);
      return [];
    }
  };

  const loadMore = async () => {
    const nextPage = currentPage + 1;
    const newRecords = await fetchExchangePair(nextPage);
    if (newRecords.length > 0) {
      setCurrentPage(nextPage);
      setExPair((prevData) => [...prevData, ...newRecords]);
    }
  };

  useEffect(() => {
    const initialFetch = async () => {
      const initialRecords = await fetchExchangePair(1);
      if (initialRecords.length > 0) {
        setExPair(initialRecords);
      }
    };
    initialFetch();
  }, [exchangeId]);

  return (
    <div>
      <div className="ps-2 pb-4">
        <h5 className="fw-bold text-start pt-4 ps-1">{exchangeId} Market</h5>
      </div>
      {loading ? (
        <p className="text-center text-primary">Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <Row className="ps-3">
          <Col xl="8" className="ps-3">
            <div className="table-responsive">
              <table className="table exchangeMarket">
                <thead className="bg-light">
                  <tr>
                    <th>#</th>
                    <th>Pair</th>
                    <th>Price</th>

                    {exPair.some(
                      (row) => row.volume !== null && !isNaN(Number(row.volume))
                    ) && <th>Volume</th>}
                    {exPair.some(
                      (row) =>
                        row.priceChangePercent !== null &&
                        !isNaN(Number(row.priceChangePercent))
                    ) && <th>Price Change%</th>}
                    <th>Updated</th>
                  </tr>
                </thead>
                <tbody>
                  {exPair.map((row, index) => (
                    <tr key={row.pairName} className="pb-2">
                      <td className="pb-2 pt-2">{index + 1}</td>
                      <td className="pb-2 pt-2">{row.pairName}</td>
                      <td className="pb-2 pt-2">
                        {Number(row.price).toFixed(4)}
                      </td>

                      {row.volume !== null && !isNaN(Number(row.volume)) && (
                        <td className="pb-2 pt-2">
                          {Number(row.volume).toFixed(4)}
                        </td>
                      )}
                      {row.priceChangePercent !== null &&
                        !isNaN(Number(row.priceChangePercent)) && (
                          <td className="pb-2 pt-2">
                            {Number(row.priceChangePercent).toFixed(4)}
                          </td>
                        )}
                      <td className="pb-2 pt-2">
                        {new Date(row.updatedAt).toLocaleString("en-IN", {
                          hour12: true,
                        })}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {exPair.length % 50 === 0 && (
              <div className="text-center mt-3">
                <button className="load-more-button" onClick={loadMore}>
                  Load More
                </button>
              </div>
            )}
          </Col>

          <Col xl="4" className="p-3 pt-4 d-none d-xl-block">
            <div className="text-start pt-3">
              <img
                src={banner4}
                style={{
                  width: "100%",
                  height: "600px",
                  borderRadius: "8px",
                }}
                alt="Banner 4"
              />
            </div>
            <div className="text-start mt-4 pt-4">
              <img
                src={banner5}
                style={{
                  width: "100%",
                  height: "600px",
                  borderRadius: "8px",
                }}
                alt="Banner 5"
              />
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ExchangePair;
